.header {
    background-color: #142d4c;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 5;
}

.header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 75rem;
    min-height: 4.375rem;
    margin: 0 auto;
    padding: 0 1.875rem;
}

.nav__list {
    display: flex;
    align-items: center;
    column-gap: 2.5rem;
}

.nav__link {
    color: rgba(255, 255, 255, 0.65);
    transition: all 0.4s;
}

.nav__link:hover {
    color: rgba(255, 255, 255, 1);
}

.nav__link--active {
    color: rgba(255, 255, 255, 1);
}

.logo {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
}

.cart {
    border-radius: 0.3125rem;
    transition: all 0.4s;
}

.cart:hover {
    background-color: rgba(255, 255, 255, 1);
}

.cart--active {
    background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 53.125em) {
    .header__content {
        flex-direction: column;
        padding: 1.5625rem 0;
    }

    .nav__list {
        column-gap: 1.25rem;
    }

    body {
        padding-top: 8.75rem;
    }
}

@media (max-width: 23.75em) {
    .logo {
        font-size: 2rem;
    }

    .nav__list {
        column-gap: 0.625rem;
    }

    body {
        padding-top: 7.5rem;
    }
}

