.products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
    gap: 1rem;
    align-items: flex-start;
    width: 100%;
}

.product {
    background-color: #fff;
    padding: 1rem 1rem 0.5rem 1rem;
    border: 1px solid #777;
    border-radius: 0.25rem;
    max-width: 37.5rem;
}

.product__header {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}

.product__body {
    overflow: hidden;
}

.product__body > img {
    display: block;
    width: 100%;
    max-height: 23.75rem;
    aspect-ratio: 9 / 16;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
    margin-bottom: 0.75rem;
    transition: 200ms transform ease-in-out;
}

.product:hover > .product__body > img {
    transform: scale(1.025);
}

.product__body p ~ p {
    margin-bottom: 0.5rem;
}

.product__body p + p {
    margin-top: 0.5rem;
}

.main__products {
    padding: 2rem 3.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
}

.products__heading {
    font-size: 2.5rem;
}

.products__filters {
    display: flex;
    justify-content: space-evenly;
    column-gap: 2rem;
    width: 100%;
    max-width: 37.5rem;
}

.gender__checkbox {
    margin-right: 0.25rem;
    position: relative;
}

.gender__checkbox ~ .gender__checkbox {
    margin-left: 1rem;
}

.category__dropdown {
    margin-left: 0.25rem;
    border: none;
    background-color: hsl(200, 50%, 50%);
    color: white;
    height: 2em;
    border-radius: 0.25em;
    margin-left: 0.5rem;
    padding-left: 0.25em;
}

.category__dropdown:hover {
    background-color: hsl(200, 50%, 60%);
    color: white;
}

.link {
    color: hsl(210, 100%, 40%);
}

@media (max-width: 29.7em) {
    .products__filters {
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
    }

    .main__products {
        font-size: 0.9rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .products__heading {
        font-size: 2rem;
    }

    .product__header {
        font-size: 1.2rem;
    }
}

@media (max-width: 21.875em) {
    .products {
        grid-template-columns: 1fr;
    }
}