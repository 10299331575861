.user-profile {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
}

.profile__heading {
    font-size: 2.5rem;
}

.user-details {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.user-details__heading {
    margin-bottom: 0.5rem;
}

.profile__orders {
    text-align: center;
}

.profile__orders__heading {
    margin-bottom: 0.5rem;
}

@media (max-width: 23.75em) {
    .profile__heading {
        font-size: 2rem;
    }
}