.main__home {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6.25rem;
    padding: 5rem 0;
}

.section {
    background-color: white;
    text-align: center;
    width: 100%;
    padding: 5rem;
}

.section__heading {
    margin-bottom: 1.5rem;
    font-size: 2rem;
}

.section__text {
    font-size: 1.2rem;
}