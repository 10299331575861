.main__login {
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.form__heading {
    font-size: 2.5rem;
}

.form {
    display: flex;
    flex-direction: column;
    row-gap: 0.375rem;
    width: 18rem;
    margin: 1rem auto;
}

.form input {
    height: 2rem;
    padding-left: 0.25rem;
}

.btn {
    border: none;
    border-radius: 0.25em;
    background-color: hsl(200, 50%, 50%);
    color: white;
    padding: 0.5em 0.75em;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.btn:hover {
    background-color: hsl(200, 50%, 60%);
}

.btn:disabled {
    background-color: hsl(200, 50%, 60%, 0.5);
    cursor: default;
}

.link {
    color: hsl(210, 100%, 40%);
}

@media (max-width: 23.75em) {
    .form__heading {
        font-size: 2rem;
    }

    .form {
        width: 12.5rem;
    }
}