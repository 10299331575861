.checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 0 auto;
    padding: 2rem 0.5rem;
    row-gap: 0.25rem;
}

.checkout__product {
    width: 100%;
}

.checkout__product  img {
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 1rem;
}

.link {
    color: hsl(210, 100%, 40%);
}

.checkout__heading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.FormGroup {
	padding: 0;
	border-radius: 0.25rem;;
    background-color: white;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 0.9375rem;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}
