.orders {
    padding: 2rem 1rem;
}

.orders__list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orders__heading {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.order {
    border: 1px solid #777;
    background-color: white;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
}

.order__items {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
    justify-content: space-around;
    padding: 1rem;
}

.order__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: #777;
    color: white;
    padding: 0.5rem 0.25rem;
}

.order__item img {
    display: block;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
    width: 100%;
    margin-bottom: 0.75rem;
}

.order__item__heading {
    text-align: center;
    margin-bottom: 1rem;
}

.order__item__text + .order__item__text {
    margin-top: 0.75rem;
}

.order__item__info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 23.75em) {
    .orders__heading {
        font-size: 2rem;
    }

    .order__item__heading {
        font-size: 1.3rem;
    }
}